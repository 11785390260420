import { Box, Typography, Grid, Divider, CircularProgress } from "@mui/material";
import { useEffect, useState, useRef, ChangeEvent } from "react";
import { memberType } from "../../types";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

interface PropsType {
    member_id: number | null,
    tabOpen: boolean,
    setTabOpen: (state: boolean) => void
    setTabEditMemberOpen: (state: boolean) => void
}

const AddMemberCredit = ({ member_id, tabOpen, setTabOpen, setTabEditMemberOpen }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [memberData, setMemberData] = useState<memberType | null>(null)
    const [memberCredit, setMemberCredit] = useState<number>(0);
    const amountRef = useRef<HTMLInputElement>(null);
    const otpRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        (async () => {
            if(!member_id || !tabOpen)
            {
                setMemberData(null);
                setMemberCredit(0);
                return;
            }

            try {
                const service = new ApiService();
                const getMemberData = await service.getMemberById(cookies.get('access_token'), member_id);
                if(getMemberData.data.status_code === 200)
                {
                    setMemberData(getMemberData.data.member);
                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'แจ้งเตือน',
                        text: `เกิดข้อผิดพลาด (${getMemberData.data.status_code} - ${getMemberData.data.message})`
                    });
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, [member_id, tabOpen]);

    useEffect(() => {
        if(!memberData)
        {
            return;
        }

        getMemberCredit();
    }, [memberData]);

    const getMemberCredit = async () => {
        if(!memberData)
        {
            return;
        }

        try {
            const service = new ApiService();
            const getCredit = await service.getMemberCredit(cookies.get('access_token'), memberData.ufa_username || "");

            if(getCredit.data.status_code === 200)
            {
                setMemberCredit(getCredit.data.current_credit)
            }
        } catch (err) {
            console.log(err);
        }
    }

    const addCredit = async () => {
        if(!member_id || !memberData || !amountRef.current || amountRef.current.value.trim() === '' || !otpRef.current || otpRef.current.value.trim() === '')
        {
            return;
        }

        try { 
            const service = new ApiService();
            const addCredit = await service.addCredit(cookies.get('access_token'), String(memberData.ufa_username), amountRef.current.value.trim(), otpRef.current.value.trim());

            Swal.fire({
                icon: addCredit.data.status_code === 200 ? 'success' : 'error',
                title: 'แจ้งเตือน',
                text: addCredit.data.status_code === 200 ? `เพิ่มเครดิตให้ ${memberData.ufa_username} จำนวน ${amountRef.current.value.trim()} เรียบร้อยแล้วค่ะ` : addCredit.data.message
            });
            setTabOpen(false);
            setTabEditMemberOpen(false);
        } catch (err) {
            console.log(err);
        }
    }
    
    return (
        member_id ? <>
            <Box sx={
                {
                    display: 'block',
                    maxWidth: {
                        xs: `90%`,
                        sm: `80%`,
                        md: `40%`,
                        lg: `30%`
                    },
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: tabOpen ? 0 : -3000,
                    position: 'fixed',
                    backgroundColor: localStorage.getItem('theme') === 'light' ? 'white' : '#1c222f',
                    zIndex: 10000000,
                    color: 'black',
                    transition: 'all .3s',
                    borderLeft: `solid 3px ${localStorage.getItem('theme') === 'light' ? '#e0e3e6' : 'black'}`,
                    overflowY: 'scroll'
                }
            }>
                <Box p={2}>
                    {
                        memberData ? <>
                            <div className="row">
                                <div className="col-6">
                                    <Typography fontSize={18} fontWeight={`bold`} sx={
                                        {
                                            color: localStorage.getItem('theme') === 'light' ? 'black' : '#a1b0cb'
                                        }
                                    }>
                                        เพิ่มเครดิต ({memberData.ufa_username })
                                    </Typography>
                                </div>
                                <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button className="btn btn-primary" onClick={addCredit}>เพิ่มเครดิต</button>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                                {/* <div className="col-sm-12 col-md-12">
                                    <div className="d-flex" style={
                                        {
                                            color: localStorage.getItem('theme') === 'light' ? 'black' : '#a1b0cb',
                                            justifyContent: 'flex-end'
                                        }
                                    }>
                                        <input name="default-radio-1" className="form-check-input" type="radio" id="addcredit_type_deposit" defaultChecked={true} />
                                        <label className="form-check-label" htmlFor="addcredit_type_deposit">
                                            &nbsp;ฝากเงิน
                                        </label>
                                        <input name="default-radio-1" className="form-check-input" type="radio" id="addcredit_type_credit" style={{ marginLeft: 10 }} />
                                        <label className="form-check-label" htmlFor="addcredit_type_credit">
                                            &nbsp;เครดิต
                                        </label>
                                    </div>
                                </div> */}
                                <div className="col-sm-12 col-md-12">
                                    <div className="row" style={
                                        {
                                            color: localStorage.getItem('theme') === 'light' ? 'black' : '#a1b0cb'
                                        }
                                    }>
                                        <div className="mb-1">
                                            <label htmlFor="amount" className="form-label">
                                                จำนวน
                                            </label>
                                            <input type="number" className="form-control w-100" id="amount" name="amount"
                                                placeholder="จำนวนที่ต้องการเพิ่มเครดิต" ref={amountRef} defaultValue={'0'} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="authenticator" className="form-label">
                                                Authenticator
                                            </label>
                                            <input type="text" className="form-control w-100" id="authenticator" name="authenticator"
                                                placeholder="Authenticator" ref={otpRef} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> : <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    }
                </Box>
            </Box>
            <Box sx={
                {
                    display: tabOpen ? 'block' : 'none',
                    maxWidth: `100%`,
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: 0,
                    position: 'fixed',
                    backgroundColor: 'rgba(0, 0, 0, .7)',
                    zIndex: 10000000 - 1,
                    transition: 'all .5s'
                }
            } onClick={() => setTabOpen(false)} />
        </> : null
    )
}

export default AddMemberCredit;