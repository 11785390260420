import { Link } from "react-router-dom";
import { memberType } from "../../types";
import { useState } from "react";
import Cookies from "universal-cookie";

interface PropsType {
    member: memberType;
    reloadMember: () => void;
    setSelectMember: (member_id: number) => void;
}

const MembersTableBody = ({ member, reloadMember, setSelectMember }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [recreating, setRecreating] = useState<boolean>(false);

    return (
        <tr>
            <td>
                {member.id}
            </td>
            <td align="center">
                {member.first_name}&nbsp;{member.last_name}
            </td>
            <td align="center">
                {member.ufa_username}
            </td>
            <td align="center">
                {member.bank}&nbsp;(X-{member.bank_number.slice(6, 10)})
            </td>
            <td align="center">
                {
                    parseFloat(String(member.credit)).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })
                }
            </td>
            <td align="center">
                {
                    member.status === 1 ? "ปกติ" : (member.status === 2) ? "ระงับการเข้าเล่น" : "ระงับการใช้งาน"
                }
            </td>
            <td align="center">
                <Link to={`/member`} onClick={() => setSelectMember(member.id)}>
                    <i className="bx bx-show" />
                </Link>
            </td>
        </tr>
    )
}

export default MembersTableBody;