import { useRef, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

// FONTS
// import './../../assets/vendor/fonts/boxicons.css'
// import './../../assets/vendor/fonts/fontawesome.css'
// import './../../assets/vendor/fonts/flag-icons.css'

// CSS
import './../../assets/vendor/css/rtl/core.css';
import './../../assets/vendor/css/rtl/theme-default.css';
import './../../assets/css/demo.css';
import './../../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';
import './../../assets/vendor/libs/typeahead-js/typeahead.css';
// import './../../assets/vendor/libs/@form-validation/umd/styles/index.min.css'
// import './../../assets/vendor/css/pages/page-auth.css'

// IMAGES
import LoginLight from './../../assets/img/pages/login-light.png';
import LogoBlackBg from './../../assets/img/logo_black_bg.png';

const Login = () => {
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const otpRef = useRef<HTMLInputElement>(null);

    const handleLogin = async (e: FormEvent) => {
        e.preventDefault();

        try {
            if(!usernameRef.current || !passwordRef.current || !otpRef.current)
            {
                return;
            }

            const username = usernameRef.current.value.trim();
            const password = passwordRef.current.value.trim();
            const otp = otpRef.current.value.trim();

            if(username === '')
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'ชื่อผู้ใช้งานไม่สามารถเว้นว่างได้'
                });
            }
            else if(password === '')
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'รหัสผ่านไม่สามารถเว้นว่างได้'
                });
            }

            const service = new ApiService();
            const login = await service.login({
                username,
                password,
                otp
            });
            const { data } = login;

            if(data.status_code === 200 && data.message === 'Success')
            {
                const expires = new Date();
                expires.setDate(expires.getDate() + 1);
                cookies.set('access_token', data.admin_data.access_token, { expires });
                
                if(data.admin_data.secret_authentication)
                {
                    cookies.set('secret_authentication', data.admin_data.secret_authentication, { expires });
                    cookies.set('otpauth_url', data.admin_data.otpauth_url, { expires });
                    return navigate('/verify');
                }

                return navigate('/');
            }

            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: data.message || 'ไม่ทราบสาเหตุข้อผิดพลาด'
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="authentication-wrapper authentication-cover" style={{ backgroundColor: 'white', borderRadius: 5, padding: 10 }}>
                <div className="authentication-inner row m-0">
                    <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center">
                        <div className="flex-row text-center mx-auto">
                            <img src={LoginLight} alt="Auth Cover Bg color" width={520}
                                className="img-fluid authentication-cover-img"
                                data-app-light-img="pages/login-light.png"
                                data-app-dark-img="pages/login-dark.png" />
                        </div>
                    </div>
                    <div
                        className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                        <div className="w-px-400 mx-auto">
                            <div className="app-brand mb-4">
                                <a href="/" className="app-brand-link gap-2 mb-2">
                                    <img src={LogoBlackBg} width="100%" />
                                </a>
                            </div>
                            <h4 className="mb-2">Welcome to Bethub! 👋</h4>
                            <form id="formAuthentication" className="mb-3" onSubmit={handleLogin}>
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label"
                                        style={{ color: '#51659e' }}>
                                        Username
                                    </label>
                                    <input type="text" className="form-control" id="username" name="username"
                                        placeholder="username" ref={usernameRef} />
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label" htmlFor="password"
                                            style={{ color: '#51659e' }}>
                                            Password
                                        </label>
                                    </div>
                                    <div className="input-group input-group-merge">
                                        <input type={showPassword ? 'text' : 'password'} id="password" className="form-control"
                                            name="password" placeholder="············"
                                            aria-describedby="password" ref={passwordRef} />
                                        <span className="input-group-text cursor-pointer" onClick={() => setShowPassword(showPassword ? false : true)}>
                                            <i className={`bx bx-${showPassword ? 'show' : 'hide'}`} />
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="otp" className="form-label"
                                        style={{ color: '#51659e' }}>
                                        Authenticator
                                    </label>
                                    <input type="text" className="form-control" id="otp" name="otp"
                                        placeholder="Authenticator" ref={otpRef} />
                                </div>
                                {/* <div className="mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="remember-me" />
                                        <label className="form-check-label" htmlFor="remember-me"
                                            style={{ color: '#51659e' }}>
                                            Remember Me
                                        </label>
                                    </div>
                                </div> */}
                                <button className="btn btn-primary d-grid w-100" type="submit">Sign in</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;