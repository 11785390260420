import { Box, Typography, IconButton, Grid, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { adminPermissionsType, listMenuType } from "../../types";
import './../../assets/css/sidebar.css';
import Logo from './../../assets/images/logo.png';

const listMenu: listMenuType[] = [
    {
        label: 'ภาพรวม',
        icon: '',
        to: null,
        permission: ''
    },
    {
        label: 'หน้าหลัก',
        icon: 'menu-icon tf-icons bx bx-home-circle',
        to: '/',
        permission: ''
    },
    {
        label: 'จัดการหวย',
        icon: 'menu-icon tf-icons bx bx-cog',
        to: '/managelotto',
        permission: 'managelotto'
    },
    {
        label: 'สมาชิกทั้งหมด',
        icon: 'menu-icon tf-icons bx bx-user',
        to: '/member',
        permission: 'member'
    },
    {
        label: 'รายงาน',
        icon: '',
        to: null,
        permission: ''
    },
    {
        label: 'รายงานสมาชิกใหม่',
        icon: 'menu-icon tf-icons bx bx-clipboard',
        to: '/report-all',
        permission: 'report_all'
    },
    {
        label: 'รายงาน (ยอดฝาก)',
        icon: 'menu-icon tf-icons bx bx-data',
        to: '/report-data',
        permission: 'report_all_data'
    },
    {
        label: 'รายงาน (ฝากถอนแยกสมาชิก)',
        icon: 'menu-icon tf-icons bx bxs-user-account',
        to: '/report-member',
        permission: 'report_all_data'
    },
    {
        label: 'รายงานชวนเพื่อน',
        icon: 'menu-icon tf-icons bx bxl-graphql',
        to: '/report-affiliate',
        permission: 'report_all_data'
    },
    // {
    //     label: 'รายงาน (หวย)',
    //     icon: 'menu-icon tf-icons bx bx-circle-three-quarter',
    //     to: '/report-lotto',
    //     permission: 'report_all'
    // },
    {
        label: 'รายงาน (คลิก)',
        icon: 'menu-icon tf-icons bx bx-network-chart',
        to: '/report-click',
        permission: 'report_click'
    },
    {
        label: 'รายงานยอดฝาก SCB',
        icon: 'menu-icon tf-icons bx bx-wallet-alt',
        to: '/report-sum-deposit-total',
        permission: 'report_all'
    },
    {
        label: 'ธุรกรรมการเงิน',
        icon: '',
        to: null,
        permission: ''
    },
    // {
    //     label: 'รายการที่สร้าง (SCB)',
    //     icon: 'menu-icon tf-icons bx bx-notepad',
    //     to: '/transaction-scb-created-list',
    //     permission: 'deposit_scb'
    // },
    {
        label: 'รายการฝาก SCB',
        icon: 'menu-icon tf-icons bx bx-list-plus',
        to: '/transaction-deposit-scb',
        permission: 'deposit_scb'
    },
    {
        label: 'รายการฝาก Payment',
        icon: 'menu-icon tf-icons bx bx-list-plus',
        to: '/transaction-deposit',
        permission: 'deposit_transactions'
    },
    {
        label: 'รายการถอนทั้งหมด',
        icon: 'menu-icon tf-icons bx bx-list-minus',
        to: '/transaction-withdraw',
        permission: 'withdraw_transactions'
    },
    {
        label: 'อื่นๆ',
        icon: '',
        to: null,
        permission: ''
    },
    {
        label: 'โปรโมชั่น',
        icon: 'menu-icon tf-icons bx bx-cloud',
        to: '/promotion',
        permission: 'promotion'
    },
    {
        label: 'จัดการแอดมิน',
        icon: 'menu-icon tf-icons bx bxs-user-detail',
        to: '/create-admin',
        permission: 'create_admin'
    },
    {
        label: 'ตั้งค่าเว็บไซต์',
        icon: 'menu-icon tf-icons bx bx-cog',
        to: '/settings',
        permission: 'settings'
    },
    {
        label: 'ตั้งค่าบัญชี',
        icon: 'menu-icon tf-icons bx bxs-bank',
        to: '/managebankaccount',
        permission: 'manange_bank_account'
    },
    {
        label: 'ออกจากระบบ',
        icon: 'menu-icon tf-icons bx bx-arrow-back',
        to: '/logout',
        permission: ''
    },
]

interface PropsType {
    isOpen: boolean,
    handleToggleOpen: (state: boolean) => void,
    permissions: adminPermissionsType
}

const Sidebar = ({ isOpen, handleToggleOpen, permissions }: PropsType) => {
    let location = useLocation();
    const [pathName, setPathName] = useState<string | null>(null);

    useEffect(() => {
        setPathName(location.pathname);
    }, [location]);
    return (
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
            <div className="app-brand demo">
                <a href="/" className="app-brand-link">
                    <img src={Logo} width="100%" />
                </a>
                {/* <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto">
                    <i className="bx menu-toggle-icon d-none d-xl-block fs-4 align-middle" />
                    <i className="bx bx-x d-block d-xl-none bx-sm align-middle" />
                </a> */}
            </div>
            <div className="menu-divider mt-0" />
            <div className="menu-inner-shadow" />
            <ul className="menu-inner py-1" style={{
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none'
            }}>
                {
                    permissions !== null && listMenu.map((item: listMenuType, index: number) => {
                        return (
                            !item.permission || item.permission && permissions[item.permission as keyof adminPermissionsType] ? <li className={`menu-item ${pathName === item.to && "active"}`} key={index}>
                                {
                                    item.to ? <Link to={item.to} className="menu-link">
                                        <i className={`${item.icon}`} />
                                        <div>{item.label}</div>
                                    </Link> : <div style={{ fontSize: 12, marginTop: 2 }}>
                                        {item.label}
                                    </div>
                                }
                                
                            </li> : null
                        )
                    })
                }
                {/* <li className="menu-item">
                    <a href="#" className="menu-link" onClick={() => handleToggleOpen(isOpen ? false : true)}>
                        <i className={`menu-icon tf-icons bx bx-${isOpen ? 'x' : 'fullscreen'}`} />
                        <div>ซ่อน</div>
                    </a>
                </li> */}
            </ul>
        </aside>
    )
}

export default Sidebar;