import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import { loginFormType, createMemberType, memberScbTransactionDepositType, adminPermissionsType, createLottoType, updatePeriodResultsType, lottosType } from './../types';

export class ApiService {
    private baseURL: string | undefined;
    private instance: AxiosInstance;

    constructor(timeout?: number | undefined | null) {
        this.baseURL = process.env.REACT_APP_API_URL;
        this.instance = axios.create({
            baseURL: this.baseURL,
            timeout: timeout || 120 * 1000,
            timeoutErrorMessage: "Time out!",
        });
    }

    getBaseURL = (): string | undefined => {
        return this.baseURL;
    }

    get = async (path: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.get(path, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    post = async (path: string, body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.post(path, body, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    getProfile = async(access_token: string): Promise<AxiosResponse> => {
        return this.instance.get('/admin/get/profile', {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    changePassword = async (access_token: string, username: string, old_password: string, new_password: string): Promise<AxiosResponse> => {
        return this.instance.post('/admin/changepassword', {
            username,
            old_password,
            new_password
        },{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    createAdmin = async (access_token: string, username: string, password: string, name: string, permissions: adminPermissionsType): Promise<AxiosResponse> => {
        return this.instance.post('/admin/register', {
            username,
            password,
            name,
            permissions
        },{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    editProfile = async (access_token: string, name: string): Promise<AxiosResponse> => {
        return this.instance.post('/admin/editprofile', {
            name
        },{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    check_access_token = async(access_token: string): Promise<AxiosResponse> => {
        return this.instance.post('/admin/checkaccesstoken', {}, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    login = async(body: loginFormType, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.post('/admin/login', body, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    countAllMember = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/count/members`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMembers = async(access_token: string, page: number, limit: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/members/${page}/${limit}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberById = async (access_token: string, member_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/member/${member_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    searchMembers = async(access_token: string, search: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/get/members`, {
            search: search
        },{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    searchMembersForMatch = async(access_token: string, search: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/get/membersformatch`, {
            search: search
        },{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMember = async(access_token: string, member_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/member/${member_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    updateRolling = async(access_token: string, id: number, state: number): Promise<AxiosResponse> => {
        return this.instance.post('/admin/update/rolling', {
            id,
            state
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    updateCommission = async(access_token: string, id: number, state: number): Promise<AxiosResponse> => {
        return this.instance.post('/admin/update/commission', {
            id,
            state
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    deleteMember = async(access_token: string, id: number): Promise<AxiosResponse> => {
        return this.instance.post('/admin/delete/member', {
            id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    createMember = async (access_token: string, createMember: createMemberType): Promise<AxiosResponse> => {
        return this.instance.post('/admin/create/member', {
            password: createMember.password,
            phone: createMember.phone,
            first_name: createMember.first_name,
            last_name: createMember.last_name,
            line: createMember.line,
            know_at: createMember.know_at,
            bank: createMember.bank,
            bank_number: createMember.bank_number
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    recreateUfaUser = async (access_token: string, member_id: number): Promise<AxiosResponse> => {
        return this.instance.post('/admin/recreate', {
            member_id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getDepositTransactions = async (access_token: string, page: number, limit: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/transactions/deposit/${page}/${limit}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getDepositTransactionsScb = async (access_token: string, page: number, limit: number, onlyNotMatch: boolean): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/transactions/depositscb/${page}/${limit}/${onlyNotMatch ? 'true' : 'false'}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getListTransactionsScb = async (access_token: string, page: number, limit: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/scb/created/list/${page}/${limit}}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getWithdrawTransactions = async (access_token: string, page: number, limit: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/transactions/withdraw/${page}/${limit}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getDepositTransactionsByMemberId = async (access_token: string, member_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/transactions/deposit/${member_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getDepositTransactionsScbByMemberId = async (access_token: string, member_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/transactions/depositscb/${member_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getWithdrawTransactionsByMemberId = async (access_token: string, member_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/transactions/withdraw/${member_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getWebsiteSettings = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/website/settings`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    setActiveAgentById = async (access_token: string, agent_id: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/set/activeagent`, {
            agent_id: agent_id
        },{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    setActivePaymentById = async (access_token: string, payment_id: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/set/activepayment`, {
            payment_id: payment_id
        },{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    addCredit = async (access_token: string, ufa_username: string, amount: string, otp: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/add/credit`, {
            ufa_username,
            amount: amount.replace(',', ''),
            otp: otp
        },{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    removeCredit = async (access_token: string, ufa_username: string, amount: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/remove/credit`, {
            ufa_username,
            amount: amount.replace(',', '')
        },{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    createAgent = async (access_token: string, username: string, password: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/create/agent`, {
            username,
            password
        },{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getLogAddCredit = async (access_token: string, page: number, limit: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/log/add/credit/${page}/${limit}`,{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getLogRemoveCredit = async (access_token: string, page: number, limit: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/log/remove/credit/${page}/${limit}`,{
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getTurnoverDataByAgentUsername = async (access_token: string, agent_username:string, start_date: string, end_date: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/get/turnover`,{
            agent_username: agent_username,
            start_date: start_date,
            end_date: end_date
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    rematchScbTransaction = async (access_token: string, transaction: memberScbTransactionDepositType, member_id: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/rematch/scb`,{
            transaction: transaction,
            member_id: member_id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getPromotions = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/promotion`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    createPromotion = async (access_token: string, data: FormData): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/create/promotion`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberCredit = async (access_token: string, username: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/membercredit/${username}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getDashboardMember = async (access_token:string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/dashboard/newmember`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberDataDetailMemberPage = async (access_token:string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/dashboard/memberdashboard`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getDepositWithdrawSum = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/dashboard/depositwithdrawdata`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getChartDataDashboard = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/dashboard/chartdata`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getRegisterByWhoDashboard = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/dashboard/registerbywho`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberDataDashboard = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/dashboard/newmember`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReferrerDashboard = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/dashboard/referrer`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReportData = async (access_token: string, start_date: string, end_date: string, isZean: boolean): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/report/${start_date}/${end_date}`, {
            is_zean: isZean
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReportLotto = async (access_token: string, start_date: string, end_date: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/reportlotto/${start_date}/${end_date}`, {}, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReportAllData = async (access_token: string, start_date: string, end_date: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/reportall/${start_date}/${end_date}`, {}, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReportMemberData = async (access_token: string, start_date: string, end_date: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/get/reportByMember/${start_date}/${end_date}`, {}, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReportAffiliateData = async (access_token: string, start_date: string, end_date: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/get/reportaffiliate/${start_date}/${end_date}`, {}, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReportClick = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/report/click`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReportSumDepositTotal = async (access_token: string, page: number, limit: number, start_date: string | null, end_date: string | null): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/get/report/sumdeposit`, {
            page: page,
            limit: limit,
            start_date: start_date,
            end_date: end_date
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getLottos = async (access_token: string, page: number, limit: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/lotto/${page}/${limit}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    createLotto = async (access_token: string, data: FormData): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/create/lotto`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    updateLottoWithFile = async (access_token: string, data: FormData): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/lotto/updatewithfile`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    updateLottoNonFile = async (access_token: string, data: createLottoType): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/lotto/updatenonfile`, data, {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    deleteLotto = async (access_token: string, id: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/delete/lotto`, {
            id: id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    loadLottoDataForEdit = async (access_token: string, lotto_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/lotto/${lotto_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    createLottoPeriod = async (access_token: string, lotto_id: number, period_name: string, start_dt: string, end_dt: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/lotto/createperiod`, {
            lotto_id: lotto_id,
            start_dt: start_dt,
            end_dt: end_dt,
            period_name: period_name
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    deleteLottoPeriod = async (access_token: string, lotto_id: number, period_id: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/lotto/deleteperiod`, {
            lotto_id: lotto_id,
            period_id: period_id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    activeLottoPeriod = async (access_token: string, lotto_id: number, period_id: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/lotto/activeperiod`, {
            lotto_id: lotto_id,
            period_id: period_id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    activeResultLottoPeriod = async (access_token: string, lotto_id: number, period_id: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/lotto/activeresultperiod`, {
            lotto_id: lotto_id,
            period_id: period_id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    updatePeriodResults = async (access_token: string, period_id: number, data: updatePeriodResultsType): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/update/period/results`, {
            period_id: period_id,
            data: data
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    loadDataPeriodResults = async (access_token: string, period_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/period/results/${period_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    loadLimitNumberByPeriodIdAndLottoId = async (access_token: string, lotto_id: number, period_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/limitnumber/${lotto_id}/${period_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    deleteLimitNumberByPeriodIdAndLottoId = async (access_token: string, lotto_id: number, period_id: number, number: string, state: number, subType: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/lotto/delete/limit/number`, {
            lotto_id: lotto_id,
            period_id: period_id,
            number: number,
            state: state,
            subtype: subType
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    addLimitNumber = async (access_token: string, lotto_id: number, period_id: number, number: string, state: number, subType: number, pay: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/lotto/add/limit/number`, {
            lotto_id: lotto_id,
            period_id: period_id,
            number: number,
            state: state,
            subtype: subType,
            pay: pay
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getBills = async (access_token: string, lotto_id: number, period_id: number, page: number, limit: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/bills/${lotto_id}/${period_id}/${page}/${limit}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    payBillByPeriodId = async (access_token: string, lotto_id: number, period_id: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/pay/lotto`, {
            lotto_id,
            period_id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getBillById = async (access_token: string, bill_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/lotto/bill/${bill_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getTopnumberLotto = async (access_token: string, lotto_id: string | number, period_id: string | number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/lotto/topnumber/${lotto_id}/${period_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    toggleDisableLotto = async (access_token: string, lotto_id: string | number, disable_number: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/lotto/toggle/disable`, {
            lotto_id: lotto_id,
            disable_number: disable_number
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    updateLottoSort = async (access_token: string, lottos: lottosType[]): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/lotto/update/sort`, {
            lottos: lottos
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    updateStatusSpeedPay = async (access_token: string, payment_id: string, status: string, return_credit: boolean): Promise<AxiosResponse> => {
        return this.instance.post(`/se/update/withdraw/status`, {
            id: payment_id,
            status: status,
            return_credit: return_credit
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    updateStatusZPay = async (access_token: string, payment_id: string, status: string, return_credit: boolean): Promise<AxiosResponse> => {
        return this.instance.post(`/zpay/update/withdraw/status`, {
            id: payment_id,
            status: status,
            return_credit: return_credit
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    updateStatusBullPay = async (access_token: string, payment_id: string, status: string, return_credit: boolean): Promise<AxiosResponse> => {
        return this.instance.post(`/bullpay/update/withdraw/status`, {
            id: payment_id,
            status: status,
            return_credit: return_credit
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getListAdmin = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/admin`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberDepositTransactions = async (access_token: string, member_id: number, page: number, limit: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/member/deposit/transactions/${member_id}/${page}/${limit}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberWithdrawTransactions = async (access_token: string, member_id: number, page: number, limit: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/member/withdraw/transactions/${member_id}/${page}/${limit}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getScbBalance = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/scb/get/balance`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getSpeedPayBalance = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/se/get/balance`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getZPayBalance = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/zpay/get/balance`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getBullpayBalance = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/bullpay/get/balance`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    sendWithdrawDataToPayment = async (access_token: string, path_payment: string, payout_id: number): Promise<AxiosResponse> => {
        return this.instance.post(`/${path_payment}/send/payout`, {
            payout_id: payout_id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    toggleShowPromotion = async (access_token: string, promotion_id: number, toggle_number: number): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/toggle/promotion`, {
            promotion_id: promotion_id,
            toggle_number: toggle_number
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberLogGetPromotions = async (access_token: string, member_id: number, page: number, limit: number): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/get/log/promoiton/${member_id}/${page}/${limit}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getBankAccount = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/admin/bank/account`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    updateBankCondition = async (access_token: string, bank_id: number, value: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/update/bank/condition`, {
            bank_id: bank_id,
            condition: value
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    updateBankConditionValue = async (access_token: string, bank_id: number, value: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/update/bank/condition/value`, {
            bank_id: bank_id,
            condition_value: value
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    verifyOtp = async (access_token: string, secret_authentication: string, otpauth_url: string, otp: string): Promise<AxiosResponse> => {
        return this.instance.post(`/admin/verify/authentication`, {
            secret_authentication: secret_authentication,
            otpauth_url: otpauth_url,
            otp: otp
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }
}