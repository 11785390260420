import Cookies from "universal-cookie";
import LoginLight from './../../assets/img/pages/login-light.png';
import LogoBlackBg from './../../assets/img/logo_black_bg.png';
import { Link, useNavigate } from "react-router-dom";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ApiService } from "../../services/api.service";

// CSS
import './../../assets/vendor/css/rtl/core.css';
import './../../assets/vendor/css/rtl/theme-default.css';
import './../../assets/css/demo.css';
import './../../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';
import './../../assets/vendor/libs/typeahead-js/typeahead.css';
import { Typography } from "@mui/material";
import Swal from "sweetalert2";

import PageLoading from "../PageLoading/PageLoading";

const SetAuth = () => {
    const cookies = new Cookies(null, { path: '/' });
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const otpRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if(!cookies.get('otpauth_url'))
        {
            navigate('/');
        }
    }, []);

    const handleSet = async (e: FormEvent) => {
        e.preventDefault();

        if(!otpRef.current)
        {
            return;
        }

        setLoading(true);
        try {
            const otp = otpRef.current.value.trim();

            const service = new ApiService();
            const verify = await service.verifyOtp(cookies.get('access_token'), cookies.get('secret_authentication'), cookies.get('otpauth_url'), otp);

            if(verify.data.status_code === 200)
            {
                cookies.remove('secret_authentication');
                cookies.remove('otpauth_url');
                navigate('/');
            }
            else
            {
                cookies.remove('access_token');
                cookies.remove('username');
                cookies.remove('secret_authentication');
                cookies.remove('otpauth_url');
                Swal.fire({
                    icon: 'error',
                    title: 'Notification',
                    text: `ERROR (${verify.data.status_code} - ${verify.data.message})`
                });
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const copyKey = () => {
        navigator.clipboard.writeText(cookies.get('secret_authentication'));
    }

    return (
        <>
            {
                loading ? <PageLoading /> : null
            }
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="authentication-wrapper authentication-cover" style={{ backgroundColor: 'white', borderRadius: 5, padding: 10 }}>
                    <div className="authentication-inner row m-0">
                        <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center">
                            <div className="flex-row text-center mx-auto">
                                <img src={LogoBlackBg} alt="Auth Cover Bg color" width={520}
                                    className="img-fluid authentication-cover-img"
                                    data-app-light-img="pages/login-light.png"
                                    data-app-dark-img="pages/login-dark.png" />
                            </div>
                        </div>
                        <div
                            className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
                            <div className="w-px-400 mx-auto">
                                <div className="app-brand demo mb-3">
                                    <Link to="/" className="app-brand-link" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        {/* <img src={Logo} width="100%" /> */}
                                        <Typography fontSize={28} fontWeight={`bold`} align="center">
                                            BETHUB
                                        </Typography>
                                    </Link>
                                    {/* <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto">
                                        <i className="bx menu-toggle-icon d-none d-xl-block fs-4 align-middle" />
                                        <i className="bx bx-x d-block d-xl-none bx-sm align-middle" />
                                    </a> */}
                                </div>
                                <form id="formAuthentication" className="mb-3" onSubmit={handleSet}>
                                    <div className="mb-1">
                                        <img src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${cookies.get('otpauth_url')}`} alt="QRCODE" style={{
                                            width: '100%'
                                        }} />
                                    </div>
                                    <div className="mb-1">
                                        <label htmlFor="otp" className="form-label"
                                            style={{ color: '#51659e' }}>
                                            Authenticator
                                        </label>
                                        <input type="text" className="form-control" id="otp" name="otp"
                                            placeholder="Authenticator" ref={otpRef} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="otp_key" className="form-label"
                                            style={{ color: '#51659e' }}>
                                            Key
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <input
                                                type={'text'} id="otp_key" className="form-control"
                                                name="otp_key" placeholder="Key"
                                                aria-describedby="Key" value={cookies.get('secret_authentication')}
                                                readOnly
                                            />
                                            <span className="input-group-text cursor-pointer" onClick={copyKey}>
                                                <i className="bx bx-clipboard"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary d-grid w-100" type="submit">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )   
}

export default SetAuth;