import { Box, Typography, Grid, Divider, CircularProgress } from "@mui/material";
import { useEffect, useState, useRef, ChangeEvent } from "react";
import { memberType } from "../../types";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import MemberDepositTransaction from "./MemberDepositTransaction";
import MemberWithdrawTransaction from "./MemberWithdrawTransaction";
import MemberLogGetPromotions from "./MemberLogGetPromotions";
import AddMemberCredit from "./AddMemberCredit";

interface PropsType {
    member_id: number | null,
    tabOpen: boolean,
    setTabOpen: (state: boolean) => void
}

const bank_list = [
    {
        "bank_abbr": "BBL",
        "bank_code": "002",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bbl.png",
        "bank_name_en": "Bangkok Bank",
        "bank_name_th": "ธนาคารกรุงเทพ"
    },
    {
        "bank_abbr": "KBANK",
        "bank_code": "004",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kbank.png",
        "bank_name_en": "Kasikorn Bank",
        "bank_name_th": "ธนาคารกสิกรไทย"
    },
    {
        "bank_abbr": "KTB",
        "bank_code": "006",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ktb.png",
        "bank_name_en": "Thai Bank",
        "bank_name_th": "ธนาคารกรุงไทย"
    },
    {
        "bank_abbr": "TTB",
        "bank_code": "011",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ttb.png",
        "bank_name_en": "TMBThanachart Bank",
        "bank_name_th": "ธนาคารทหารไทยธนชาต"
    },
    {
        "bank_abbr": "SCB",
        "bank_code": "014",
        "bank_logo": "https://cdn.bullpay.me/images/logo/scb.png",
        "bank_name_en": "The Siam Commercial Bank",
        "bank_name_th": "ธนาคารไทยพาณิชย์"
    },
    {
        "bank_abbr": "BAY",
        "bank_code": "025",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bay.png",
        "bank_name_en": "Bank of Ayudhya Bank",
        "bank_name_th": "ธนาคารกรุงศรีอยุธยา"
    },
    {
        "bank_abbr": "KKP",
        "bank_code": "069",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kkp.png",
        "bank_name_en": "KIATNAKIN PHATRA BANK",
        "bank_name_th": "ธนาคารเกียรตินาคินภัทร"
    },
    {
        "bank_abbr": "CIMBT",
        "bank_code": "022",
        "bank_logo": "https://cdn.bullpay.me/images/logo/cimb.png",
        "bank_name_en": "CIMB Thai Bank",
        "bank_name_th": "ธนาคารซีไอเอ็มบีไทย"
    },
    {
        "bank_abbr": "TISCO",
        "bank_code": "067",
        "bank_logo": "https://cdn.bullpay.me/images/logo/tisco.png",
        "bank_name_en": "TISCO Bank",
        "bank_name_th": "ธนาคารทิสโก้"
    },
    {
        "bank_abbr": "UOBT",
        "bank_code": "024",
        "bank_logo": "https://cdn.bullpay.me/images/logo/uob.png",
        "bank_name_en": "United Chinese Bank",
        "bank_name_th": "ธนาคารยูโอบี"
    },
    {
        "bank_abbr": "LHFG",
        "bank_code": "073",
        "bank_logo": "https://cdn.bullpay.me/images/logo/lhbank.png",
        "bank_name_en": "Land and Houses",
        "bank_name_th": "ธนาคารแลนด์ แอนด์ เฮ้าส์"
    },
    {
        "bank_abbr": "BAAC",
        "bank_code": "034",
        "bank_logo": "https://cdn.bullpay.me/images/logo/baac.png",
        "bank_name_en": "Bank for Agriculture and Agricultural Cooperatives",
        "bank_name_th": "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
    },
    {
        "bank_abbr": "GSB",
        "bank_code": "030",
        "bank_logo": "https://cdn.bullpay.me/images/logo/gsb.png",
        "bank_name_en": "Government Savings Bank",
        "bank_name_th": "ธนาคารออมสิน"
    },
    {
        "bank_abbr": "GHB",
        "bank_code": "033",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ghb.png",
        "bank_name_en": "Government Housing Bank",
        "bank_name_th": "ธนาคารอาคารสงเคราะห์"
    },
    {
        "bank_abbr": "TMW",
        "bank_code": "099",
        "bank_logo": "https://cdn.bullpay.me/images/logo/truemoney.png",
        "bank_name_en": "True Money Wallet",
        "bank_name_th": "ทรูมันนี่วอลเล็ท"
    },
    {
        "bank_abbr": "TBANK",
        "bank_code": "065",
        "bank_logo": "https://cdn.bullpay.me/images/logo/tbank.png",
        "bank_name_en": "Thanachart Bank Public Company Limited",
        "bank_name_th": "ธนาคารธนชาต"
    },
    {
        "bank_abbr": "SCBT",
        "bank_code": "020",
        "bank_logo": "https://cdn.bullpay.me/images/logo/scbt.png",
        "bank_name_en": "Standard Chartered Bank (Thai) Public Company Limited",
        "bank_name_th": "ธนาคารสแตนดาร์ดชาร์เตอร์ด ประเทศไทย"
    },
    {
        "bank_abbr": "TCRB",
        "bank_code": "071",
        "bank_logo": "https://cdn.bullpay.me/images/logo/tcrb.png",
        "bank_name_en": "The Thai Credit Retail Bank Public Company Limited",
        "bank_name_th": "ธนาคารไทยเครดิต"
    },
    {
        "bank_abbr": "ISBT",
        "bank_code": "066",
        "bank_logo": "https://cdn.bullpay.me/images/logo/isbt.png",
        "bank_name_en": "ISLAMIC BANK OF THAILAND",
        "bank_name_th": "ธนาคารอิสลาม"
    },
    {
        "bank_abbr": "SMEB",
        "bank_code": "098",
        "bank_logo": "https://cdn.bullpay.me/images/logo/smeb.png",
        "bank_name_en": "Small and Medium Enterprise Development Bank of Thailand",
        "bank_name_th": "ธนาคารเพื่อเอสเอมอี"
    },
    {
        
        "bank_abbr": "ACL",
        "bank_code": "070",
        "bank_logo": "https://cdn.bullpay.me/images/logo/acl.png",
        "bank_name_en": "ACL Bank Public Company Limited",
        "bank_name_th": "ACL Bank"
    }
];

const EditMember = ({ member_id, tabOpen, setTabOpen }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const [memberData, setMemberData] = useState<memberType | null>(null)
    const [memberCredit, setMemberCredit] = useState<number>(0);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [memberDepositAmount, setMemberDepositAmount] = useState<number>(0);
    const [memberWithdrawAmount, setMemberWithdrawAmount] = useState<number>(0);

    const [openTabAddCredit, setOpenTabAddCredit] = useState<boolean>(false);

    const copyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value);
    }

    useEffect(() => {
        (async () => {
            if(!member_id || !tabOpen)
            {
                setMemberData(null);
                setMemberCredit(0);
                setShowPassword(false);
                return;
            }
            
            try {
                const service = new ApiService();
                const getMemberData = await service.getMemberById(cookies.get('access_token'), member_id);
                if(getMemberData.data.status_code === 200)
                {
                    setMemberData(getMemberData.data.member);
                    setMemberDepositAmount(getMemberData.data.deposit_total);
                    setMemberWithdrawAmount(getMemberData.data.withdraw_total);
                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'แจ้งเตือน',
                        text: `เกิดข้อผิดพลาด (${getMemberData.data.status_code} - ${getMemberData.data.message})`
                    });
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, [member_id, tabOpen]);

    useEffect(() => {
        if(!memberData)
        {
            return;
        }

        getMemberCredit();
    }, [memberData]);

    const getMemberCredit = async () => {
        if(!memberData)
        {
            return;
        }

        try {
            const service = new ApiService();
            const getCredit = await service.getMemberCredit(cookies.get('access_token'), memberData.ufa_username || "");

            if(getCredit.data.status_code === 200)
            {
                setMemberCredit(getCredit.data.current_credit)
            }
        } catch (err) {
            console.log(err);
        }
    }

    const addCredit = () => {
        if(!member_id || !memberData)
        {
            return;
        }

        setOpenTabAddCredit(true);

        // setTabOpen(false);
        // Swal.fire({
        //     title: `เพิ่มเครดิตให้ ${memberData.ufa_username}`,
        //     input: "text",
        //     inputAttributes: {
        //         autocapitalize: "off",
        //     },
        //     showCancelButton: true,
        //     confirmButtonText: "เพิ่ม",
        //     cancelButtonText: "ยกเลิก"
        // }).then(async (res) => {
        //     if(res.isConfirmed)
        //     {
        //         const service = new ApiService();
        //         const addCredit = await service.addCredit(cookies.get('access_token'), String(memberData.ufa_username), res.value);

        //         Swal.fire({
        //             icon: addCredit.data.status_code === 200 ? 'success' : 'error',
        //             title: 'แจ้งเตือน',
        //             text: addCredit.data.status_code === 200 ? `เพิ่มเครดิตให้ ${memberData.ufa_username} จำนวน ${res.value} เรียบร้อยแล้วค่ะ` : addCredit.data.message
        //         });
        //     }
        // });
    }

    const removeCredit = () => {
        if(!member_id || !memberData)
        {
            return;
        }

        setTabOpen(false);
        Swal.fire({
            title: `ลบเครดิตของ ${memberData.ufa_username}`,
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "ลบ",
            cancelButtonText: "ยกเลิก"
        }).then(async (res) => {
            if(res.isConfirmed)
            {
                const service = new ApiService();
                const removeCredit = await service.removeCredit(cookies.get('access_token'), String(memberData.ufa_username), res.value);

                Swal.fire({
                    icon: removeCredit.data.status_code === 200 ? 'success' : 'error',
                    title: 'แจ้งเตือน',
                    text: removeCredit.data.status_code === 200 ? `ลบเครดิตของ ${memberData.ufa_username} จำนวน ${res.value} เรียบร้อยแล้วค่ะ` : removeCredit.data.message
                });
            }
        });
    }
    
    return (
        <>
            {
                member_id ? <>
                    {
                        openTabAddCredit ? <AddMemberCredit member_id={member_id} setTabOpen={setOpenTabAddCredit} tabOpen={openTabAddCredit} setTabEditMemberOpen={setTabOpen} /> : <>
                            <Box sx={
                                {
                                    display: 'block',
                                    maxWidth: {
                                        xs: `90%`,
                                        sm: `90%`,
                                        md: `80%`,
                                        lg: `60%`
                                    },
                                    maxHeight: `100vh`,
                                    width: '100%',
                                    height: '100%',
                                    top: 0,
                                    right: tabOpen ? 0 : -3000,
                                    position: 'fixed',
                                    backgroundColor: localStorage.getItem('theme') === 'light' ? 'white' : '#1c222f',
                                    zIndex: 10000000,
                                    color: 'black',
                                    transition: 'all .3s',
                                    borderLeft: `solid 3px ${localStorage.getItem('theme') === 'light' ? '#e0e3e6' : 'black'}`,
                                    overflowY: 'scroll'
                                }
                            }>
                                <Box p={2}>
                                    {
                                        memberData ? <>
                                            <div className="row">
                                                <div className="col-6">
                                                    <Typography fontSize={18} fontWeight={`bold`} sx={
                                                        {
                                                            color: localStorage.getItem('theme') === 'light' ? 'black' : '#a1b0cb'
                                                        }
                                                    }>
                                                        สมาชิก:  {memberData.ufa_username }
                                                    </Typography>
                                                </div>
                                                <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <button className="btn btn-primary" onClick={() => {}}>แก้ไข</button>
                                                </div>
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                                <div className="col-6">
                                                    <button className="btn btn-primary w-100" onClick={addCredit}>เพิ่มเครดิต</button>
                                                </div>
                                                <div className="col-6">
                                                    <button className="btn btn-danger w-100" onClick={removeCredit}>ลบเครดิต</button>
                                                </div>
                                                <div className="col-12">
                                                    <hr/>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="row" style={
                                                        {
                                                            color: localStorage.getItem('theme') === 'light' ? 'black' : '#a1b0cb'
                                                        }
                                                    }>
                                                        <div className="col-12">
                                                            <label className="form-label" htmlFor="member_phone">เบอร์:</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="member_phone" value={memberData.phone || ""} aria-describedby="member_phone_span" readOnly />
                                                                <span id="member_phone_span" className="input-group-text cursor-pointer" onClick={() => copyToClipboard(memberData.phone || "")}>
                                                                    <i className="bx bx-clipboard"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="form-label" htmlFor="member_username">ชื่อผู้ใช้:</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="member_username" value={memberData.ufa_username || ""} aria-describedby="member_username_span" readOnly />
                                                                <span id="member_username_span" className="input-group-text cursor-pointer" onClick={() => copyToClipboard(memberData.ufa_username || "")}>
                                                                    <i className="bx bx-clipboard"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="form-label" htmlFor="member_password">รหัสผ่าน:</label>
                                                            <div className="input-group">
                                                                <span id="member_password_span" className="input-group-text cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                                                                    <i className={`bx bx-${showPassword ? 'show' : 'hide'}`} />
                                                                </span>
                                                                <input type={showPassword ? 'text' : 'password'} className="form-control" id="member_password" value={memberData.password || ""} aria-describedby="member_password_span" readOnly />
                                                                <span id="member_password_span" className="input-group-text cursor-pointer" onClick={() => copyToClipboard(memberData.password || "")}>
                                                                    <i className="bx bx-clipboard"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-12">
                                                            <label className="form-label" htmlFor="member_line">LINE:</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="member_line" value={member.line || ""} aria-describedby="member_line_span" readOnly />
                                                                <span id="member_line_span" className="input-group-text cursor-pointer" onClick={() => copyToClipboard(member.line || "")}>
                                                                    <i className="bx bx-clipboard"></i>
                                                                </span>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="row" style={
                                                        {
                                                            color: localStorage.getItem('theme') === 'light' ? 'black' : '#a1b0cb'
                                                        }
                                                    }>
                                                        <div className="col-12">
                                                            <label className="form-label" htmlFor="member_fullname">ชื่อ-นามสกุล:</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="member_fullname" value={`${memberData.first_name} ${memberData.last_name}` || ""} aria-describedby="member_fullname_span" readOnly />
                                                                <span id="member_fullname_span" className="input-group-text cursor-pointer" onClick={() => copyToClipboard(`${memberData.first_name} ${memberData.last_name}` || "")}>
                                                                    <i className="bx bx-clipboard"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="form-label" htmlFor="member_banknumber">เลขบัญชี:</label>
                                                            <div className="input-group">
                                                                <span id="member_number_span" className="input-group-text">
                                                                    <img src={bank_list[bank_list.findIndex((element) => element.bank_abbr === memberData.bank)].bank_logo} alt="logo" style={{ width: '100%', maxWidth: '20px' }} />
                                                                </span>
                                                                <input type="text" className="form-control" id="member_banknumber" value={memberData.bank_number || ""} aria-describedby="member_number_span" readOnly />
                                                                <span id="member_number_span" className="input-group-text cursor-pointer" onClick={() => copyToClipboard(memberData.bank_number || "")}>
                                                                    <i className="bx bx-clipboard"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="form-label" htmlFor="member_credit">เครดิตปัจจุบัน:</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="member_credit" value={parseFloat(String(memberCredit)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} aria-describedby="member_credit_span" readOnly />
                                                                <span id="member_credit_span" className="input-group-text cursor-pointer" onClick={() => copyToClipboard(String(memberCredit))}>
                                                                    <i className="bx bx-clipboard"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <div className="row" style={
                                                        {
                                                            color: localStorage.getItem('theme') === 'light' ? 'black' : '#a1b0cb'
                                                        }
                                                    }>
                                                        <div className="col-12">
                                                            <label className="form-label" htmlFor="member_deposit_amount">ยอดฝากทั้งหมด:</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="member_deposit_amount" value={`${parseFloat(String(memberDepositAmount)).toLocaleString('en-US', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })}`} aria-describedby="member_deposit_amount_span" readOnly />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="form-label" htmlFor="member_withdraw_amount">ยอดถอนทั้งหมด:</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="member_withdraw_amount" value={`${parseFloat(String(memberWithdrawAmount)).toLocaleString('en-US', {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                })}`} aria-describedby="member_withdraw_amount_span" readOnly />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <hr/>
                                                </div>
                                                <div className="col-12">
                                                    <MemberDepositTransaction member_id={memberData.id} />
                                                </div>
                                                <div className="col-12">
                                                    <hr/>
                                                </div>
                                                <div className="col-12">
                                                    <MemberWithdrawTransaction member_id={memberData.id} />
                                                </div>
                                                <div className="col-12">
                                                    <hr/>
                                                </div>
                                                <div className="col-12">
                                                    <MemberLogGetPromotions member_id={memberData.id} />
                                                </div>
                                            </div>
                                        </> : <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                            <CircularProgress />
                                        </div>
                                    }
                                </Box>
                            </Box>
                            <Box sx={
                                {
                                    display: tabOpen ? 'block' : 'none',
                                    maxWidth: `100%`,
                                    maxHeight: `100vh`,
                                    width: '100%',
                                    height: '100%',
                                    top: 0,
                                    right: 0,
                                    position: 'fixed',
                                    backgroundColor: 'rgba(0, 0, 0, .7)',
                                    zIndex: 10000000 - 1,
                                    transition: 'all .5s'
                                }
                            } onClick={() => setTabOpen(false)} />
                        </>
                    }
                    
                </> : null
            }
        </>
    )
}

export default EditMember;